import React, { useContext } from "react"
import { useEffect } from "react"
import { Helmet } from "react-helmet"
import PagesItems from "../components/PagesItems"
import PageContext from "../context/PageContext"

const Page = ({ data, classes, pageContext }) => {
  // const [context, dispatch] = useContext(PageContext)

  // useEffect(() => {
  //   pageContext.pageUrl &&
  //     dispatch({
  //       type: "SET_BREADCRUMB",
  //       payload: {
  //         breadCrumbPath: [
  //           { menuName: pageContext.title, url: "/" + pageContext.pageUrl },
  //         ],
  //       },
  //     })

  //   //eslint-disable-next-line
  // }, [])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageContext.title} | FÖDA</title>
      </Helmet>
      <div className="relative px-4 sm:px-6 lg:px-8 lg:py-10 lg:pb-20 pb-20">
        <div
          dangerouslySetInnerHTML={{ __html: pageContext.pageContent }}
          className="mt-6 prose prose-amber max-w-6xl   text-gray-500 mx-auto pb-10"
        ></div>
        <div className=" prose prose-amber  max-w-6xl text-gray-500 mx-auto pb-10">
          <PagesItems pageContext={pageContext} />
        </div>
      </div>
    </>
  )
}

export default Page
