import React from "react";  

const Components = {
};


const block = (block) => {
  if (typeof Components[block.name] !== "undefined") {
    return React.createElement(Components[block.name], {
      block: block.name,
      param: block.param,
      data: block.data
    });
  }
  return React.createElement(
    () => <div>The component {block.name} has not been created yet.</div>
  );
};

export default block