import React from "react";
import Components from "../components/components.js";

export default function PagesItems(props) {
    let pagesItems
    if(props.pageContext.pagesItems) {
        pagesItems = JSON.parse(props.pageContext.pagesItems);
    }

    function displayPagesItems(data) {
        let component;
        if(data.component) {
            component = {
                name: data.component,
                param: data.param
            }
        } 
        
        return (
            
            <div className={data.class + (data.img ? " overflow-hidden" : "")} key={data.id} >
                {data.component && 
                   <>
                    {Components(component)}
                   </>
                }
                {data.img ? (
                    <img src={data.img} alt="" className="p-3" />
                ) : (
                    data.content && 
                    <div dangerouslySetInnerHTML={{__html: data.content}} /> 
                )
                }
                
                {data.children && data.children.map((child) => { return displayPagesItems(child);})}
                
            </div>
             
        );
    }

    
    return (
        <>
            {pagesItems && pagesItems.map((pageItem) => {
               return displayPagesItems(pageItem);
            })
            }
        </>
    )
}